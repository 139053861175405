<template>
    <v-app>
        <SaveQuoteDialog :onComplete="$event => closeWindow($event)"></SaveQuoteDialog>
        
        <div aria-live="polite" :class="isLoading ? '' : 'd-sr-only'">
            <v-overlay absolute color="white" v-if="isLoading" style="z-index: 6; width: 101%;">
                <div class="dot-typing"></div>
                <span class="d-sr-only">Loading... Please Wait!</span>
            </v-overlay> 
        </div>
        <v-app-bar app color="white" aria-label="Header" elevation="6" ref="headerRef" :height=" $vuetify.breakpoint.mdAndUp ? 86 : ( this.partnerLogoSource !== '' ? 110 : 40)" extension-height="65">
            <v-row class="d-flex align-center" :class="$vuetify.breakpoint.smAndDown && this.partnerLogoSource !== '' ? 'fill-height':''">
                <v-col md="4" cols="12" class="logo-section d-flex justify-center justify-md-start"  :class="$vuetify.breakpoint.smAndDown && this.partnerLogoSource !== '' ? 'hasPartnerLogo':''">
                    <div id="logo-wrapper" class="d-flex  ma-0" :class="$vuetify.breakpoint.smAndDown && this.partnerLogoSource !== '' ? 'flex-wrap justify-space-between align-center flex-column': ' align-center flex-row'">
                        <v-img 
                            alt="Partner Logo"
                            class="shrink partnerlogo-img"
                            contain
                            :src="partnerLogoSource"
                            transition="scale-transition"
                            :max-width="getPartnerLogoWidth()"
                            max-height="50"
                            width="auto"
                            v-on:click="goToLogoURL(partnerLogoUrl)"
                            :data-partnerID="this.$store.getters.partnerId"
                            v-if="partnerLogoSource !== ''"
                        />
                        <v-divider role="none" v-if="$vuetify.breakpoint.mdAndUp && partnerLogoSource !== '' && !companyLogoOverride" class="mx-4" vertical></v-divider>
                        <v-img v-if="logoSource && !companyLogoOverride"
                            alt="MetLife logo"
                            class="shrink mr-2"
                            contain
                            :src="logoSource"
                            transition="scale-transition"
                            width="auto"
                            max-height="56"
                            max-width="250"
                        />
                        <v-img v-else
                            v-if="!companyLogoOverride"
                            alt="MetLife logo"
                            class="shrink mr-2"
                            contain
                            src="@/assets/images/ML_PetInsurance_Snoopy_Horizontal_Color_March.png"
                            transition="scale-transition"
                            width="auto"
                            :max-height="$vuetify.breakpoint.mdAndUp ? 56 : 28"
                            :max-width="$vuetify.breakpoint.mdAndUp ? 400 : 300"
                        />
                    </div>
                </v-col>
                <v-col cols="4" class="justify-center" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-stepper id="appStepper" v-model="step" compact max-height="86" alt-labels flat class="flex-grow-1">
                        <v-stepper-header>
                            <v-stepper-step class="step-1" step="1" :complete="steps.pet.complete && !steps.pet.active">
                                <span v-if="path !== 'renewal'" class="caption">About Your Pet</span>
                                <span v-if="steps.pet.complete && !steps.pet.active" class="d-sr-only" aria-live="polite">Step 1 Completed</span>
                                <span v-else-if="steps.pet.active" class="d-sr-only" aria-live="polite">Step 1 Active</span>
                            </v-stepper-step>
                            
                            <div class="hr-container">
                                <v-divider role="none"></v-divider>
                            </div>

                            <v-stepper-step class="step-2" step="2" :complete="steps.pricing.complete && !steps.pricing.active">  
                                <span v-if="path !== 'renewal'" class="caption">Customize Your Plan</span>
                                <span v-if="steps.pricing.complete && !steps.pricing.active" class="d-sr-only" aria-live="polite">Step 2 Completed</span>
                                <span v-else-if="steps.pricing.active" class="d-sr-only" aria-live="polite">Step 2 Active</span>
                            </v-stepper-step>

                            <div class="hr-container">
                                <v-divider role="none"></v-divider>
                            </div>

                            <v-stepper-step class="step-3" step="3" :complete="steps.billing.complete && steps.payment.complete && !steps.billing.active && !steps.payment.active">  
                                <span v-if="path !== 'renewal'" class="caption">Contact & Billing</span>
                                <span v-if="steps.billing.complete && steps.payment.complete && !steps.billing.active && !steps.payment.active" class="d-sr-only" aria-live="polite">Step 3 Completed</span>
                                <span v-else-if="steps.payment.active" class="d-sr-only" aria-live="polite">Step 3 Active</span>
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                </v-col>
                <v-col cols="6" md="4" class="d-flex justify-end"  v-if="$vuetify.breakpoint.mdAndUp">
                    <v-list-item id="Appbar-contact" two-line class="align-self-end d-flex list-item-end">
                        <v-list-item-content class="align-end">
                            <v-list-item-subtitle class="ml_darkblue--text" v-if="$vuetify.breakpoint.mdAndUp">Call a pet advocate:</v-list-item-subtitle>
                            <v-list-item-title>
                                <a aria-labelledby="partner-phone-contact" class="ml_darkblue--text" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEvent()">  {{this.getNumbertoAlphanumberic(this.getPartnerContactInfo())}}</a>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    
                    <v-list-item class="align-self-center d-flex list-item-end">
                        <v-btn icon color="black" class="btn-vertical" v-on:click="closeWindow" aria-label="Exit/Logout Metlife Quote Funnel" ref="exitButtonApp">
                            <v-icon>mdi-close</v-icon> 
                            <span>Exit</span>
                        </v-btn>
                    </v-list-item>
                </v-col>
            </v-row>
            <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
                <v-stepper id="appStepper" v-model="step" compact max-height="65" alt-labels flat class="flex-grow-1">
                        <v-stepper-header role="list">
                            <v-stepper-step class="step-1" step="1" :complete="steps.pet.complete && !steps.pet.active" role="listitem">
                            <span v-if="path !== 'renewal'" class="caption">About Your Pet</span>
                            <span v-if="steps.pet.complete && !steps.pet.active" class="d-sr-only" aria-live="polite">Step 1 Completed</span>
                            <span v-else-if="steps.pet.active" class="d-sr-only" aria-live="polite">Step 1 Active</span>
                            </v-stepper-step>

                            <div class="hr-container">
                                <v-divider role="none"></v-divider>
                            </div>

                            <v-stepper-step class="step-2" step="2"  :complete="steps.pricing.complete && !steps.pricing.active" role="listitem">
                            <span v-if="path !== 'renewal'" class="caption">Customize Your Plan</span>
                            <span v-if="steps.pricing.complete && !steps.pricing.active" class="d-sr-only" aria-live="polite">Step 2 Completed</span>
                            <span v-else-if="steps.pricing.active" class="d-sr-only" aria-live="polite">Step 2 Active</span>
                            </v-stepper-step>

                            <div class="hr-container">
                                <v-divider role="none"></v-divider>
                            </div>

                            <v-stepper-step class="step-3" step="3" :complete="steps.billing.complete && steps.payment.complete && !steps.billing.active && !steps.payment.active" role="listitem">
                            <span v-if="path !== 'renewal'" class="caption">Contact & Billing</span>
                            <span v-if="steps.billing.complete && steps.payment.complete && !steps.billing.active && !steps.payment.active" class="d-sr-only" aria-live="polite">Step 3 Completed</span>
                            <span v-else-if="steps.payment.active" class="d-sr-only" aria-live="polite">Step 3 Active</span>
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
            </template>
        </v-app-bar>
        <v-main>
            <router-view />
            <transition name="modal">
                <Modal :key=modalKey>
                    <span slot="modal-heading">{{ modal.content.heading }}</span>
                    <div slot="modal-body"><div v-html="modal.content.body"></div></div>
                    <span slot="modal-button--submit">{{ modal.content.buttons.submit }}</span>
                    <span slot="modal-button--cancel">{{ modal.content.buttons.cancel }}</span>
                </Modal>
            </transition>
        </v-main>

        <div class="global-footer">
            <Footer ref="footer" :onClose="$event => closeWindow($event)"></Footer>
        </div>
    </v-app>
</template>
<script>
    import Modal from '../Modal.vue';
    import layout from './Global/JS/layout.js';
    import Footer from "@/components/shared/Footer.vue";
    import SaveQuoteDialog from '@/components/shared/SaveQuoteDialog.vue';
    export default {
        mixins: [layout],
        components: {
            Modal, Footer, SaveQuoteDialog
        },
        data () {
            return {
                step: 1,
            }
        },
        computed: {
            steps(){
                return this.$store.getters.steps;            
            },
            path() {
                let path = this.$route.path;

                if(this.$route.params.id) {
                    const regex = /^\/Policy\/\d+\/renewal-options$/; 
                    const match = this.$route.path.match(regex); 

                    if (match) path = 'renewal'
                }

                return path;
            }
        },
        watch: {
            steps: {
                handler(newValue,oldValue) {
                    this.setActiveStep(this.steps);
                },
                deep: true  
            }
        },
        methods: {
            setActiveStep(steps) {
                if(steps.pet.active) this.step = 1;
                if(steps.pricing.active) this.step = 2;
                if(steps.billing.active && this.steps.payment.active) this.step = 3;
                if(steps.summary.active) this.step = 4;
            },
            trackAdobeEvent() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: this.getPartnerContactInfo()
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            }, 
            async closeWindow(callFrom) {
                this.showSwalClose(callFrom);
            }, 
        },
        created() {
            this.setActiveStep(this.steps);
        },
        mounted(){
            let applogoContainer = document.querySelector('#logo-wrapper div');
            if (applogoContainer) applogoContainer.setAttribute('aria-label','MetLife Pet Insurance');
            setTimeout(()=>{
                let container = document.getElementById('appStepper').firstChild;
                let listItems = container.querySelectorAll('div.v-stepper__step');
                container.setAttribute("role","list");
                listItems.forEach((list)=>list.setAttribute("role","listitem"));
            },1000)
        }
    }
</script>

<style>

.list-item-end{
    flex: 0 !important;
}
#logo-wrapper{
    max-width: 100%;
}
</style>    